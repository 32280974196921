import { API_PATH_ANALYTICS } from "@cospex/client/constants";
import TextInput from "@cospex/client/forms/TextInput";
import {
  getItemWithExpiry,
  getSourceParams,
  setItemWithExpiry,
} from "@cospex/client/helpers";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Alert, Box } from "@mui/material";
import { a, useSpring } from "@react-spring/web";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useMeasure from "react-use-measure";
import { z } from "zod";

const ParcelLocatorModal = lazy(
  () => import("@cospex/client/parcel/components/ParceLocatorModal")
);

type ParcelLocatorForm = {
  trackingNumber: string;
  email: string;
};

/**
 * Parcel locator component. Has 2 main states:
 *
 * 1. User is logged in, and we can use their email to find the parcel. In this case, we only need the tracking number.
 *    When the tracking request is successful, we redirect to the dashboard.
 *
 * 2. User is not logged in, and we need their email to find the parcel. In this case, we need both the tracking number and the email.
 *    The email field is hidden by default, and is only shown when the user starts typing in the tracking number field.
 *    We also show a tracking modal and animation. When the animation finishes, we redirect to the payment page.
 *
 */
export default function ParcelLocator({ onTrack }: { onTrack?: () => void }) {
  const defaultTrackingNumber = getItemWithExpiry("onboardingNumber");
  const defaultEmail = getItemWithExpiry("onboardingEmail");

  const { t, i18n } = useTranslation();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const [open, setOpen] = useState(false);
  const [measureRef, { height }] = useMeasure();
  const { session } = useAuth();
  const [trackingError, setTrackingError] = useState<string | boolean>(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [emailFieldVisible, setEmailFieldVisible] = useState(
    !session && !!defaultTrackingNumber
  );
  const styles = useSpring({
    height: emailFieldVisible ? height : 0,
  });
  const { utm_source, utm_medium, utm_campaign, gclid, fbclid, msclkid } =
    getSourceParams();

  const { control, handleSubmit, formState, getValues } =
    useForm<ParcelLocatorForm>({
      defaultValues: {
        trackingNumber: (!session && defaultTrackingNumber) || "",
        email: (!session && defaultEmail) || "",
      },
      resolver: zodResolver(
        z.object({
          trackingNumber: z
            .string()
            .min(1, "parcel-form-tracking-invalid")
            .refine(
              (value) => {
                return /^[a-zA-Z0-9\s]+$/.test(value);
              },
              {
                message: "parcel-form-tracking-alphanumeric",
              }
            ),
          ...(!session && {
            email: z.string().email("parcel-form-email-invalid"),
          }),
        })
      ),
    });

  const onAnimated = () => {
    setAnimationFinished(true);
  };

  const newUserMutation = useMutation({
    networkMode: "always",
    mutationFn: ({ trackingNumber, email }: ParcelLocatorForm) => {
      trackingNumber = trackingNumber.replace(/\s/, "");
      setOpen(true);
      return axios.post("/api/parcel/onboarding", {
        trackingNumber,
        sourceParams: getSourceParams(),
        email,
        language: i18n.language,
      });
    },
    onSuccess: ({ data: { id } }: any, { trackingNumber, email }) => {
      setItemWithExpiry("onboardingNumber", trackingNumber.replace(/\s/, ""));
      setItemWithExpiry("onboardingEmail", email);
      setItemWithExpiry("onboardingId", id);
      axios.post(API_PATH_ANALYTICS, {
        email,
        utm_source,
        utm_medium,
        utm_campaign,
        gclid,
        fbclid,
        msclkid,
      });
    },
    onError: (data: any) => {
      const apiError = data.response?.data?.error;
      setTrackingError(apiError || true);
      setOpen(false);
    },
  });

  const loggedInMutation = useMutation({
    mutationFn: ({ trackingNumber }: { trackingNumber: string }) =>
      axios.post("/api/parcel/locate", {
        trackingNumber,
        language: i18n.language,
      }),
    onSuccess: () => {
      onTrack?.();
    },
    onError: (data: any) => {
      const apiError = data.response?.data?.error;
      setTrackingError(apiError || true);
    },
  });

  const onSubmit = () => {
    if (session) {
      loggedInMutation.mutate({ trackingNumber: getValues("trackingNumber") });
    } else {
      newUserMutation.mutate({
        trackingNumber: getValues("trackingNumber"),
        email: getValues("email"),
      });
    }
  };
  const onChange = (e: TextInputChangeEvent) => {
    if (session) {
      // There's an email, no need to do anything
      return;
    }
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (e.target.value.length > 0) {
        setEmailFieldVisible(true);
      } else {
        setEmailFieldVisible(false);
      }
    }, 200);
  };

  useEffect(() => {
    if (animationFinished && newUserMutation.isSuccess) {
      onTrack?.();
    }
  }, [animationFinished, newUserMutation.isSuccess]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ p: "4px 4px 0" }}>
          <TextInput
            data-test="input-tracking-number"
            onChangeCb={onChange}
            formState={formState}
            control={control}
            name="trackingNumber"
            label={t("parcel-form-label")}
            fullWidth
          />
        </Box>
        <a.div style={{ ...styles, padding: "0 4px", overflow: "hidden" }}>
          <div ref={measureRef}>
            <TextInput
              sx={{
                mt: 2,
                mb: "4px",
              }}
              formState={formState}
              control={control}
              name="email"
              label={t("parcel-form-email-label")}
              fullWidth
            />
          </div>
        </a.div>
        <Box
          style={{
            padding: "0 4px",
          }}
        >
          <LoadingButton
            sx={{ mt: 2 }}
            disableElevation
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            loading={
              loggedInMutation.isLoading || newUserMutation.isLoading || open
            }
          >
            <span>{t("parcel-form-button")}</span>
          </LoadingButton>
        </Box>
      </form>
      {trackingError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {typeof trackingError === "string" ? (
            trackingError
          ) : (
            <>
              There has been an issue with your tracking request, please try
              again later. There may be a problem with your connection or an
              issue on our side. If the problem persists, please contact us.
            </>
          )}
        </Alert>
      )}
      <Suspense>
        {open && <ParcelLocatorModal onAnimated={onAnimated} />}
      </Suspense>
    </>
  );
}
