import Logo from "@/components/Logo";
import CompanyImg from "@/img/company-name.png";
import Header from "@cospex/client/components/Header";
import Footer from "@cospex/client/parcel/components/Footer";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <>
      <Header menu={{ dashboardHref: "/dashboard" }} />
      <Outlet />
      <Footer
        logo={<Logo />}
        companyInfoImage={CompanyImg}
        languages={[
          { value: "en", label: "EN 🇬🇧" },
          { value: "fr", label: "FR 🇫🇷" },
          { value: "it", label: "IT 🇮🇹" },
        ]}
      />
    </>
  );
}
