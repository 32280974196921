import { getToken } from "@cospex/client/context/AuthProvider";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { FloatingCard } from "@cospex/client/parcel/components/Layout";
import ParcelLocator from "@cospex/client/parcel/components/ParcelLocator";
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const getStatus = async (id: string): Promise<string> => {
  return axios
    .get<string>("/api/parcel/" + id + "/status", {
      withCredentials: true,
      headers: { Authorization: "Bearer " + getToken() },
    })
    .then((res) => res.data);
};

function Status({ parcel }: { parcel: Parcel }) {
  const { t } = useTranslation();
  const [status, setStatus] = useState<string | null>(null);
  const notFound = "status-tracking-not-found";
  const searching = "status-tracking-searching";
  const trackingError = "status-error";
  const navigate = useNavigate();

  const fetchStatus = useCallback(async () => {
    setStatus(null);
    const statusData = JSON.parse(await getStatus(parcel.id));
    const shipment = statusData.shipments?.[0];
    if (
      Object.keys(statusData).length === 0 ||
      typeof statusData.done !== "boolean"
    ) {
      setStatus(trackingError);
    } else if (!statusData.done) {
      setStatus(searching);
      setTimeout(fetchStatus, 2000);
    } else {
      if (!shipment) {
        setStatus(trackingError);
      } else if (
        shipment.error === "NO_DATA" ||
        shipment.error === "NO_TRACKER"
      ) {
        setStatus(notFound);
      } else if (shipment.error) {
        setStatus(trackingError);
      } else {
        setStatus(shipment.status);
      }
    }
  }, [parcel.id]);

  useEffect(() => {
    fetchStatus();
  }, []);

  const disabled =
    !status ||
    status === notFound ||
    status === trackingError ||
    status === searching;

  return (
    <>
      {!status || status === searching ? (
        <CircularProgress size={25} thickness={3} />
      ) : (
        <Chip label={t(status)} sx={{ textTransform: "capitalize" }} />
      )}
      <Button
        variant="outlined"
        component="a"
        onClick={() =>
          navigate(
            disabled
              ? `/dashboard/parcel-error/${parcel.number}`
              : `/dashboard/parcels/${parcel.id}`
          )
        }
        sx={{ ml: 2 }}
        disabled={!status}
      >
        {t("dashboard-parcel-link")}
      </Button>
    </>
  );
}
export default function ParcelsList() {
  const { isLoading, error, data, refetch } = useQuery(["parcelsData"], () =>
    axios
      .get<Parcel[]>("/api/parcels", {
        withCredentials: true,
        headers: { Authorization: "Bearer " + getToken() },
      })
      .then((res) => res.data)
  );

  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h2" mb={5} mt={2}>
        {t("dashboard-overview-title")}
      </Typography>
      <FloatingCard
        sx={{
          marginBottom: 5,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <ParcelLocator onTrack={refetch} />
      </FloatingCard>
      <Typography variant="h2" mb={5}>
        {t("dashboard-overview-title-2")}
      </Typography>
      {(() => {
        if (isLoading) {
          return (
            <>
              <Skeleton width="100%" height={87} />
              <Skeleton width="100%" height={87} />
              <Skeleton width="100%" height={87} />
            </>
          );
        }
        if (error) {
          return <Alert severity="error">{t("parcels-problem-fetch")}</Alert>;
        }
        if (!data || data?.length === 0) {
          return (
            <>
              <Alert severity="info">{t("parcels-empty-list")}</Alert>
            </>
          );
        }

        return data.map((parcel) => (
          <FloatingCard sx={{ marginBottom: 2, py: 1.5 }} key={parcel.id}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent={{
                sm: "space-between",
                xs: "flex-start",
              }}
              alignItems="center"
              gap={{
                xs: 2,
              }}
            >
              <Typography variant="body1">
                # <strong>{parcel.number}</strong>
              </Typography>

              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                alignItems="center"
                gap={{
                  xs: 2,
                }}
              >
                <Status parcel={parcel} />
              </Stack>
            </Stack>
          </FloatingCard>
        ));
      })()}
    </>
  );
}
