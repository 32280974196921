import "@/i18n";
import "@/i18n";
import logoPath from "@/img/logo.svg";
import "@/style.css";
import Providers from "@cospex/client/provider";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import { Routes } from "@generouted/react-router";
import "intl-tel-input/build/css/intlTelInput.css";
import { createRoot } from "react-dom/client";

import theme from "./Theme";

const container = document.getElementById("app")!;
createRoot(container).render(
  <Providers
    theme={theme}
    customization={{
      logo: {
        src: logoPath,
      },
    }}
  >
    <Routes />
  </Providers>
);
