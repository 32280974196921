import LanguageSelect from "@cospex/client/components/LanguageSelect";
import MarketingEmail from "@cospex/client/components/MarketingEmail";
import useTranslation from "@cospex/client/hooks/useTranslation";
import PaymentProcessorImg from "@cospex/client/parcel/img/payment-processor.svg";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  styled,
} from "@mui/material";

const FooterContainer = styled(Box)`
  padding-top: 6rem;
  li {
    margin-bottom: 2rem;
  }
`;

interface IFooter {
  logo: React.ReactNode;
  languages: LanguageSelectOptions;
  companyInfoImage: string;
}

export default function Footer({ logo, languages, companyInfoImage }: IFooter) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <FooterContainer>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              {logo}
              <Typography variant="body2" sx={{ maxWidth: 300, pb: "1rem" }}>
                {t("footer-description")}
              </Typography>
              <LanguageSelect options={languages} />
              <Box sx={{ mt: "2rem" }}>
                <Box
                  component="img"
                  sx={{
                    maxWidth: 180,
                    ...(i18n.language === "fr"
                      ? {}
                      : {
                          clipPath: "inset(0 calc(100% - 140px) 0 0)",
                          transform: "translateX(+20px)",
                        }),
                  }}
                  src={PaymentProcessorImg}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <ul>
                <li>
                  <Link target="_blank" href="/login">
                    {t("footer-link-login")}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="/unsubscribe">
                    {t("footer-link-unsubscribe")}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="/prices">
                    {t("footer-link-prices")}
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <ul>
                <li>
                  <Link target="_blank" href="/terms">
                    {t("footer-link-terms")}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="/refunds">
                    {t("footer-link-refunds")}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="/privacy">
                    {t("footer-link-privacy")}
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="/cookies">
                    {t("footer-link-cookies")}
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ul>
                <li>
                  <Link target="_blank" href="/contact">
                    {t("footer-link-contact")}
                  </Link>
                </li>
              </ul>
              <MarketingEmail sx={{ pl: "1rem" }} />
            </Grid>
          </Grid>
          <FooterContainer>
            <Divider light sx={{ mb: 1 }} />
            <Typography variant="body2" color="text.secondary" mb={1.5}>
              Copyright {" © "}
              {new Date().getFullYear()}
              <Link color="inherit" href="https://material-ui.com/">
                {" "}
                {__APP_NAME__}
              </Link>
            </Typography>
            <Box
              component="img"
              sx={{
                height: "auto",
                width: "auto",
                maxWidth: 320,
                maxHeight: 96,
              }}
              src={companyInfoImage}
            />
          </FooterContainer>
        </Container>
      </FooterContainer>
    </>
  );
}
