import logoPath from "@/img/logo.svg";
import { Link } from "@mui/material";
import { Box } from "@mui/material";
import { MUIStyledCommonProps, styled } from "@mui/system";

const LogoContainer = styled(Link)`
  display: inline-block;
  transition: transform 0.2s ease-in-out;
  transform: scale(1);
  &:hover {
    transform: scale(1.05);
  }
`;

export default function Logo({ sx }: MUIStyledCommonProps) {
  return (
    <LogoContainer href="/" sx={sx}>
      <Box
        sx={{
          backgroundImage: `url(${logoPath})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          width: {
            sm: 132,
            xs: 32,
          },
          height: 32,
        }}
      />
    </LogoContainer>
  );
}
